<template>
    <BaseCard
        :entry="entry"
        :is-inline="isInline"
        class="tool-card"
    >
        <template #description>
            <p>{{ entry.description }}</p>
        </template>

        <template #meta>
            <EntryMetadata
                :entry="entry"
                :should-link-dossiers="false"
                :show-post-date="false"
                :show-dossier-dropdown="false"
            >
                <!-- <template #extra>
                    <BaseTag>Ledenvoordeel</BaseTag>
                </template> -->
            </EntryMetadata>
        </template>
    </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/BaseCard/BaseCard.vue';

defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        default: false
    }
});
</script>

<style lang="less" src="./ToolCard.less" />
